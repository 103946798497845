import {inView, animate} from "motion"

export default class AnimationUtil {
    constructor() {
        this.initAnimations()
    }

    setUp(animationType, animationTrigger, elementSelector) {
        if (animationType === 'none') {
            return
        }

        let animation = this.getAnimation(animationType)

        if (animationTrigger === 'In View') {
            this.inViewAnimation(animation, elementSelector)
            return
        }

        animate(elementSelector, animation.keyframes, animation.options)
    }

    inViewAnimation(animation, elementSelector) {
        inView(elementSelector, (animationElement) => {
            animate(animationElement.target, animation.keyframes, animation.options)
        })
    }

    getAnimation(animationType) {
        if (animationType === 'Fade Up') {
            return this.animations.FloatUp
        } else if (animationType === 'Fade Right') {
            return this.animations.FadeRight
        } else if (animationType === 'Fade Left') {
            return this.animations.FadeLeft
        } else if (animationType === 'Rotate') {
            return this.animations.Flip
        }
    }

    initAnimationProperty() {
        return {
            type: 'none',
            trigger: ''
        }
    }

    initAnimations() {
        this.animations = {
            Flip: this.flipTransform(),
            FloatUp: this.floatUpTransform(),
            FadeRight: this.fadeRightTransform(),
            FadeLeft: this.fadeLeftTransform()
        }
    }

    fadeRightTransform() {
        return {
            keyframes: {
                opacity: [0, 1],
                transform: ["translateX(-5rem)", "translateX(0px)"]
            },
            options: {
                duration: 2
            }
        }
    }

    fadeLeftTransform() {
        return {
            keyframes: {
                opacity: [0, 1],
                transform: ["translateX(5rem)", "translateX(0px)"]
            },
            options: {
                duration: 2
            }
        }
    }

    floatUpTransform() {
        return {
            keyframes: {
                opacity: [0, 1],
                transform: ["translateY(5rem)", "translateY(0px)"]
            },
            options: {
                duration: 2
            }
        }
    }

    flipTransform() {
        return {
            keyframes: {
                transform: [
                    "perspective(1200px) rotate3d(1, 0, 0, 90deg)",
                    "perspective(1200px) rotate3d(1, 0, 0, 60deg)",
                    "perspective(1200px) rotate3d(1, 0, 0, 30deg)",
                    "perspective(1200px) rotate3d(1, 0, 0, 15deg)",
                    "perspective(1200px)",
                ]
            },
            options: {
                duration: 1.5
            }
        }
    }
}